.checkOut1 {
    width: 100%;
    height: auto;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 12px 0 rgb(251, 251, 251);
    margin-bottom: 20px;

}

.errormsg {
    font-size: 12px;
    text-align: center;
    color: red;
    font-weight: 600;
}

.checkOut1inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: yellow;
    padding: 20px;
    color: black;

}

.checkoutinnerleft {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkOut1inner .downicon {
    font-size: 20px;
}

.checkoutinnerleft span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 4px 9px;
    margin-right: 20px;
}

.checkoutinnerleft span p {
    color: red;
}

.checkoutinnerleft h4 {
    color: black;
}

.checkOut1 h2 {
    font-size: 16px;
    padding: 0px 20px;
    color: black;
    margin: 15px 0px;
    width: 100%;
}

.checkOut1 h2 span {
    color: black;
    margin: 0px 5px;
}

.checkoutouter {
    width: 95%;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    padding: 0px 20px;
}

.checkname {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.checkname1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.checkname h3 {
    width: 50%;
    font-size: 16px;
}

.checkname1 h3 {
    width: 50%;
    font-size: 16px;
}

.checkname input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid gray;
    outline: none;
    background-color: #FAFAFA;
}

.checkname input::placeholder {
    color: gray;
}

.checkname1 p {
    width: 100%;
    padding-left: 15px;
    font-size: 14px;
}

.checkname1 button {
    padding: 10px 30px;
    background-color: yellow;
    color: black;
    border: none;
    border-radius: 4px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    font-weight: 700;
}

.checkname select {
    width: 100%;
    outline: none;
    padding: 10px;
    background-color: #FAFAFA;
    border-radius: 5px;
}

.checkoutbtn {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 600;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width:768px) {
    .checkOut1 {
        width: 100%;
        height: auto;
        background-color: #FAFAFA;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 12px 0 rgb(251, 251, 251);
        margin-bottom: 0px;
        margin-top: 20px;

    }

    .checkOut1inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        color: black;

    }

    .checkoutinnerleft {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .checkOut1inner .downicon {
        font-size: 20px;
    }

    .checkoutinnerleft span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 4px 9px;
        margin-right: 20px;
    }

    .checkoutinnerleft span p {
        color: red;
    }

    .checkoutinnerleft h4 {
        color: black;
    }

    .checkOut1 h2 {
        font-size: 16px;
        padding: 0px 20px;
        color: black;
        margin: 15px 0px;
        width: 100%;
    }

    .checkOut1 h2 span {
        color: black;
        margin: 0px 5px;
    }

    .checkoutouter {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #FAFAFA;
        padding: 0px 20px;
    }

    .checkname {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0px;
        padding: 0px 10px;
    }

    .checkname1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0px;
        padding: 0px 10px;

    }

    .checkname h3 {
        width: 100%;
        font-size: 16px;
    }

    .checkname1 h3 {
        width: 50%;
        font-size: 16px;
    }

    .checkname input {
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid gray;
        outline: none;
        background-color: #fafafa;
    }

    .checkname input::placeholder {
        color: gray;
    }

    .checkname1 p {
        width: 100%;
        padding-left: 15px;
        font-size: 14px;
    }

    .checkname1 button {
        padding: 10px 30px;
        background-color: yellow;
        color: black;
        border: none;
        border-radius: 4px;

    }

    .checkname select {
        width: 100%;
        outline: none;
        padding: 10px;
    }

    .checkoutbtn {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
}