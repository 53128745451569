marquee{
    height: 30px;
    display: flex;
    margin-top: 0px;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    font-size: 10px;
    background-color: yellow;
    color: black;
}
@media screen and (max-width:768px){
    marquee{
        height: 30px;
        display: flex;
        font-size: 10px;
        background-color: yellow;
        font-weight: 800;
        margin-top: 85px;
    }
}