.Banner{
    width: 100%;
    height: auto;
    z-index: -1;
    margin-top: 100px;
  }
.Banner .swiper {
    width: 100vw;
    height:auto;
  }
  
  .Banner .swiper-slide {
    height: auto;
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Banner .swiper-slide img {
    width: 100%;
    object-fit: contain;
  }
  
  .Banner .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  .Banner .myslider{
    width: 100%;
  }
  .Banner .myslider img{
    width: 100%;
    height: 100%;
  }
  .design{
    width: 100%;
  }
  .design img{
    width: 100%;
    height: 100%;
    object-fit:contain;
  }
  
  @media screen and (max-width:768px){
    .Banner{
      width: 100%;
      height: 200px;
    }
  .Banner .swiper {
      width: 100%;
      height:20vh;
    }
    
    .Banner .swiper-slide {
      width: 100%;
      height: 100px;
      text-align: center;
      font-size: 18px;
    
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    

    .Banner .swiper {
      margin-left: auto;
      margin-right: auto;
    }
    .Banner .myslider{
      width: 100%;
      height: 100%;
    }
    .Banner .myslider img{
      width: 100%;
      height: 200px;
    }
    .design{
      width: 80%;
      height: 200px;
    }
    .design img{
      width: 100%;
      height: 100%;
      object-fit:contain;
    }
    
  }