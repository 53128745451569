.home-section{
    width: 100%;
    height: 85vh;
}
.ch{
    width: 100%;
    padding: 0;
    margin: 0;
}
.home-section img{
    width: 100%;
    height: 100%;
}
.header-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("../Assets/fireworks-vector-transparent-18.png");
    
}
.cy{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cy img{
    width: 60%;
}
.header-menu-container{
    width: 80%;
    background-color: rgba(243, 240, 81, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 32px 0 gainsboro;
}
.header-container h1{
    color: var(--secondaryTextColor);
    font-family: var(--popinfont);
    font-size: 50px;
    font-weight: 500;
    text-align: start;
}
.categorysection{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,3fr);
    grid-gap: 30px;
    padding: 0px;
    margin: 20px 0px;
}
.header-container h1 span{
    color:blue;
}
.header-container p{
    font-size: 23px;
    font-weight: 400;
    color: black;
    text-align: start;
    width: 100%;
    line-height: 40px;
    margin-top: 15px;
}

.top-order-section{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    padding: 60px 0;
}
.product-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 60px;
    
}
.product-card{
    width: 22%;
    height: 100%;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.product-img{
    width: 100%;
    height: 200px;
}
.product-title{
    text-align: start;
    margin-top: 20px;
}
.btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.order-btn{
    width: 115px;
    height: 40px;
    background-color: var(--primaryBgColor);
    color: var(--primaryTextColor);
    border: none;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
}
.categories-section{
    width: 90%;
    height: 100%;
    margin: 0 auto;

}
.categories-section h1{
    color: var(--secondaryTextColor);
    font-family: var(--popinfont);
    font-size: 42px;
    font-weight: 500;
    text-align: start;
}
.categories-section h1 span{
    color: var(--primaryBgColor);
}
.categories-card-container{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
    padding: 50px 0;
}
.categories-card{
    width: 20%;
    height: 300px;
    border: 1px solid var(--secondaryTextColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-radius: 10px;
    cursor: pointer;
    
}
.categories-card:hover{
    border: 2px solid var(--primaryBgColor);
}
.categories-card img{
    width: 170px;
    height: 170px;
    border-radius: 50%;
}
.categories-card p{
    color: var(--primaryBgColor);
    font-size: 20px;
    font-weight: 500;
    font-family: var(--popinFont);
    cursor: pointer;
}
.serices-section{
    width: 90%;
    margin: 0 auto;
    height: 100%;
}
.serices-section h1{
    color: var(--secondaryTextColor);
    font-family: var(--popinfont);
    font-size: 42px;
    font-weight: 500;
    text-align: start;
}
.serices-section h1 span{
    color: var(--primaryBgColor);
}
.service-card-container{
    width: 100%;
    height: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.service-card{
    width: 60%;
    height: 100%;
    padding: 30px;
    background-color:rgb(250, 250, 62);
    position: relative;
    border-radius: 10px;
    box-shadow: 0 8px 32px 0 gainsboro;
}
.service-card-2 .cridiv{
    width: 400px;
    height: 400px;
}
.cridiv img{
    width: 100%;
    height: 100%;
    object-fit: contain;
 
}
.cridiv .cri1{
    width: 300px;
}

.sub-images{
    width: 30%;
    height: 100%;
    margin-right: 70px;
    border-radius: 10px 80px 10px 80px;
}
.main-service-card{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.service-icon{
    color: blue;
    font-size: 70px;
    font-weight: 300;
}
.main-service-card h4{
    color: var(--secondaryTextColor);
    font-family: var(--interFont);
    font-size: 20px;

}
.main-service-card img{
    width: 320px;
    height: 320px;
}
.main-service-card p{
    color: rgb(42, 41, 41);
    line-height: 30px;
    font-size: 20px;
    font-family: var(--interFont);
}
.service-menus{
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 10px;
}
.learnmore-container{
    gap: 3px;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: #8300CF;
    position: absolute;
    right: 5%;
    bottom: 7%;
}
.learnmore-container p{
    color: blue;
}
.arrow-right{
    cursor: pointer;
    margin-top: 3px;
    color: blue;
}
.service-card-2{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.why-choose-us-section{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 70px 0;

    /* align-items: center; */
}
.choose-img{
    width: 48%;
    height: 520px;
}
.choose-us-menus{
    width: 48%;
    text-align: start;
}
.choose-content-container{
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;
}
.bg-img{
    width: 65px;
    height: 65px;
    background-image: url(../Assets/Vector1.png);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
}
.choose-icons{
    color: var(--primaryTextColor);
    font-size: 25px;
}
.innovative{
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.innovative h4{
    font-size: 21px;
    color: var(--secondaryTextColor);
    font-weight: 500;
}
.innovative p{
    font-size: 18px;
    color: var(--paraColor);
    font-weight: 400;
}
.choose-us-menus h1{
    color: var(--secondaryTextColor);
    font-family: var(--popinfont);
    font-size: 42px;
    font-weight: 500;
    text-align: start;
    margin-bottom: 40px;
}
.choose-us-menus h1 span{
    color: var(--primaryBgColor);
}
.about-us-section{
    width: 100%;
    height: 100%;
    
}

.sub-about-us{
    width: 90%;
    height: 100%;
    margin: 0 auto;
}
.about-us-boxes-container{
    width: 100%;
    height: 100%;
    display:grid;
    grid-template-columns: repeat(10,40%);
    grid-gap: 50px;
    overflow-X: scroll;
}
.about-us-box{
    width: 2;
    height: 280px;
   background-color:rgb(250, 250, 62);
    color: black;
    background-repeat: no-repeat;
    background-position: 40% 20%;
    border-radius: 10px;
    padding: 20px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    box-shadow: 0 8px 32px 0 gainsboro;
}
.about-us-box p{
    font-size: 16px;
    color: black;
    font-weight: 400;
}
.about-us-box h4{
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color:black;
}
.profile{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile p{
    font-weight: 500;
    font-size: 20px;
    color:rgb(248, 248, 47);
}
.profile-sec{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}
.star{
    color: #EA1818;
}
.name-details h4{
    font-size: 18px;
    color: blue;
    font-weight: 500;
}
.stars-container{
    display: flex;
    gap: 3px;
}
.about-us-box2{
    background-position: top;
}
.sub-about-us h1{
    color: var(--secondaryTextColor);
    font-family: var(--popinfont);
    font-size: 42px;
    font-weight: 500;
    text-align: start;
    margin-bottom: 40px;
}
.sub-about-us h1 span{
    color: var(--primaryBgColor);
}
.diwali-img{
    width: 90%;
    height: 100%;
    margin: 80px auto;
}
.footer{
    width: 100%;
    height: auto;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.footer1{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.footer2{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footercontent{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.footer2 h2{
    font-size: 22px;
    color: yellow;
}
.f1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    
}
.f1 p{
    font-size: 16px;
    line-height: 25px;
}
.social{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}
.social h1{
    font-size: 18px;
    margin-bottom: 10px;
}
.social1{
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.social1 p{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 4px 0px 4px 0px;
}
.social1 p .soicon{
    margin-right: 10px;
    font-size: 16px;
}
.f2{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content:flex-start;
    flex-direction: column;
    padding-left: 20px;
    margin-top: 20px;
    position: relative;

}
.f2::before{
    position: absolute;
    content: "";
    width: 2px;
    height: 100px;
    background-color: yellow;
    right: 130px;
    top: 50px;
}
.f3{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content:flex-start;
    flex-direction: column;
    padding-left: 20px;
    margin-top: 20px;
    position: relative;

}
.f3 h2{
    font-size: 18px;
    margin-bottom: 20px;
}
.f3 h4{
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 13px;
}
.f2 h2{
    font-size: 18px;
    margin-bottom: 20px;
}
.f2 h4{
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 13px;
}
.footercontent1{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border-top: 1px solid white;
}

.contact-us{
    width: 20%;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.vertical-line{
    width: 2px;
    height: 100px;
    background-color: black;
}
.personal-details{
    display: flex;
    gap: 5px;
}
.social-media{
    display: flex;
    gap: 10px;
    align-items: center;
}
.last-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    border-top: 2px solid var(--secondaryTextColor);
}
.social-media a{
    text-decoration: none;
}
.videosection{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 50px 0px;
    color: white;
    background-color: black;
}
.videosec{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.videosecleft{
    width: 100%;
    display: flex;
}
.videosecleft{
    width: 100%;
    height: 100%;
}
.videosecleft iframe{
    width: 100%;
    height: 100%;

}
.videosecright{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.videobottom{
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    padding: 20px;
    font-family: var( --robotoFont);
}
.videocon{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 7px;
    background-color: #f6e999;
    box-shadow: 0 3px 12px 0 gainsboro;
}
.videocon button{
    padding: 6px 30px;
    color: black;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
}
.videocon img{
    width: 80px;
    height: 50px;
    object-fit: contain;
     margin-right: 15px;
}

.videotop{
    width: 100%;
    margin:0px 0px 20px 0px;
    padding: 0px 20px;
}
.videotop h3{
    text-align: start;
}
/* The entire scrollbar */
::-webkit-scrollbar {
    width: 0px; /* Width of the scrollbar */
    height: 0px; /* Height of the scrollbar for horizontal scrolling */
}

/* The part of the scrollbar that moves */
.videobottom::-webkit-scrollbar-thumb {
    background-color: #f6e999; /* Color of the scroll thumb */
    border-radius: 10px; /* Rounded corners */
}

/* The part of the scrollbar that stays in place */
.videobottom::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Background color of the track */
    border-radius: 10px; /* Rounded corners */
}

/* The part of the scrollbar on hover */
.videobottom::-webkit-scrollbar-thumb:hover {
    background-color: #f6e999; /* Color of the scroll thumb when hovered */
}
.botfot{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:768px){
    .home-section{
        width: 100%;
        height: 85vh;
    }
    .home-section img{
        width: 100%;
        height: 100%;
    }
    .header-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-image: url("../Assets//fireworks-vector-transparent-18.png");
        
    }
    .cy{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .cy img{
        width: 100%;
        margin: 20px 0px;
    }
    .header-menu-container{
        width: 100%;
        background-color: rgba(243, 240, 81, 0.8);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 8px 32px 0 gainsboro;
    }
    .header-container h1{
        color: var(--secondaryTextColor);
        font-family: var(--popinfont);
        font-size: 50px;
        font-weight: 500;
        text-align: start;
    }
    .header-container h1 span{
        color:blue;
    }
    .header-container p{
        font-size: 23px;
        font-weight: 400;
        color: black;
        text-align: start;
        width: 100%;
        line-height: 40px;
        margin-top: 15px;
    }
    
    .top-order-section{
        width: 90%;
        height: 100%;
        margin: 0 auto;
        padding: 0px 0;
    }
    .product-container{
        width: 100%;
        justify-content: space-between;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(4,100%);
        padding-top: 60px;
        grid-gap: 30px;
        overflow-x: scroll;
        
    }
    .product-card{
        width: 100%;
        height: 100%;
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }
    .product-img{
        width: 100%;
        height: 200px;
    }
    .product-title{
        text-align: start;
        margin-top: 20px;
    }
    .btn-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .order-btn{
        width: 115px;
        height: 40px;
        background-color: var(--primaryBgColor);
        color: black;
        border: none;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        border: 1px solid black;
    }
    .categories-section{
        width: 90%;
        height: 100%;
        margin: 0 auto;
        margin-top: 30px;
    
    }
    .categories-section h1{
        color: var(--secondaryTextColor);
        font-family: var(--popinfont);
        font-size: 42px;
        font-weight: 500;
        text-align: start;
    }
    .categories-section h1 span{
        color: var(--primaryBgColor);
    }
    .categories-card-container{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2,2fr);
        gap: 10px;
        padding: 50px 0;
    }
    .categories-card{
        width: 100%;
        height: 300px;
        border: 1px solid var(--secondaryTextColor);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        border-radius: 10px;
        cursor: pointer;
        padding: 15px;
        
    }
    .categories-card:hover{
        border: 2px solid var(--primaryBgColor);
    }
    .categories-card img{
        width: 100%;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
    }
    .categories-card p{
        color: var(--primaryBgColor);
        font-size: 20px;
        font-weight: 500;
        font-family: var(--popinFont);
        cursor: pointer;
    }
    .serices-section{
        width: 90%;
        margin: 0 auto;
        height: 100%;
    }
    .serices-section h1{
        color: var(--secondaryTextColor);
        font-family: var(--popinfont);
        font-size: 42px;
        font-weight: 500;
        text-align: start;
    }
    .serices-section h1 span{
        color: var(--primaryBgColor);
    }
    .service-card-container{
        width: 100%;
        height: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column-reverse;
    }
    .sub-images{
        width: 100%;
    }
    .service-card{
        width: 100%;
        height: 100%;
        padding: 30px;
        background-color:rgb(250, 250, 62);
        position: relative;
        border-radius: 10px;
        box-shadow: 0 8px 32px 0 gainsboro;
    }
    .service-card-2 .cridiv{
        width:100%;
    }
    .cridiv img{
        width: 100%;
        height: 100%;
        object-fit: contain;
     
    }
    .cridiv .cri1{
        width: 300px;
    }
    
    .sub-images{
        width: 100%;
        height: 100%;
        margin-right: 0px;
        border-radius: 10px 80px 10px 80px;
    }
    .main-service-card{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        gap: 40px;
    }
   
    .service-icon{
        color: blue;
        font-size: 70px;
        font-weight: 300;
    }
    .main-service-card h4{
        color: var(--secondaryTextColor);
        font-family: var(--interFont);
        font-size: 20px;
    
    }
    .main-service-card img{
        width: 320px;
        height: 320px;
    }
    .main-service-card p{
        color: rgb(42, 41, 41);
        line-height: 30px;
        font-size: 20px;
        font-family: var(--interFont);
    }
    .service-menus{
        display: flex;
        flex-direction: column;
        text-align: start;
        gap: 10px;
    }
    .learnmore-container{
        gap: 3px;
        align-items: center;
        display: flex;
        cursor: pointer;
        color: #8300CF;
        position: absolute;
        right: 5%;
        bottom: 7%;
    }
    .learnmore-container p{
        color: blue;
    }
    .arrow-right{
        cursor: pointer;
        margin-top: 3px;
        color: blue;
    }
    .service-card-2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    
        margin-top: 30px;
    }
    .why-choose-us-section{
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        padding: 70px 0;
    
        /* align-items: center; */
    }
    .choose-img{
        width: 100%;
        height: 520px;
        object-fit: contain;
    }
    .choose-us-menus{
        width: 100%;
        text-align: start;
    }
    .choose-content-container{
        width: 100%;
        display: flex;
        gap: 20px;
        margin-top: 20px;
    }
    .bg-img{
        width:85px;
        height: 85px;
        background-image: url(../Assets/Vector1.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
    .choose-icons{
        color: var(--primaryTextColor);
    }
    .innovative{
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .innovative h4{
        font-size: 21px;
        color: var(--secondaryTextColor);
        font-weight: 500;
    }
    .innovative p{
        font-size: 16px;
        color: var(--paraColor);
        font-weight: 400;
        line-height: 30px;
    }
    .choose-us-menus h1{
        color: var(--secondaryTextColor);
        font-family: var(--popinfont);
        font-size: 42px;
        font-weight: 500;
        text-align: start;
        margin-bottom: 40px;
    }
    .choose-us-menus h1 span{
        color: var(--primaryBgColor);
    }
    .about-us-section{
        width: 100%;
        height: 100%;
        
    }
    
    .sub-about-us{
        width: 90%;
        height: 100%;
        margin: 0 auto;
    }
    .categorysection{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2,2fr);
        grid-gap: 30px;
        padding: 0px;
        margin: 20px 0px;
    }
    .about-us-boxes-container{
        width: 100%;
        height: 100%;
        display:grid;
        grid-template-columns: repeat(10,100%);
        grid-gap: 50px;
        overflow-X: scroll;
    }
    .about-us-box{
        width: 2;
        height: 280px;
       background-color:rgb(250, 250, 62);
        color: black;
        background-repeat: no-repeat;
        background-position: 40% 20%;
        border-radius: 10px;
        padding: 20px;
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        box-shadow: 0 8px 32px 0 gainsboro;
    }
    .about-us-box p{
        font-size: 16px;
        color: black;
        font-weight: 400;
    }
    .about-us-box h4{
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        color:black;
    }
    .profile{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .profile p{
        font-weight: 500;
        font-size: 20px;
        color:rgb(248, 248, 47);
    }
    .profile-sec{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .star{
        color: #EA1818;
    }
    .name-details h4{
        font-size: 18px;
        color: blue;
        font-weight: 500;
    }
    .stars-container{
        display: flex;
        gap: 3px;
    }
    .about-us-box2{
        background-position: top;
    }
    .sub-about-us h1{
        color: var(--secondaryTextColor);
        font-family: var(--popinfont);
        font-size: 42px;
        font-weight: 500;
        text-align: start;
        margin-bottom: 40px;
    }
    .sub-about-us h1 span{
        color: var(--primaryBgColor);
    }
    .diwali-img{
        width: 90%;
        height: 100%;
        margin: 80px auto;
    }
    
    .footer-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 100px 5%;
        background-color: black;
        color: white;
    }
    .footer-menu-1{
        text-align: start;
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .footer-menu-1 p{
        cursor: pointer;
    }
    .footer-menu-2{
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .footer-menu-2 p{
        cursor: pointer;
    }
    .contact-us{
        width: 20%;
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .vertical-line{
        width: 2px;
        height: 100px;
        background-color: black;
    }
    .personal-details{
        display: flex;
        gap: 5px;
    }
    .social-media{
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .last-sec{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
        border-top: 2px solid var(--secondaryTextColor);
    }
    .social-media a{
        text-decoration: none;
    }
    .videosection{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin: 50px 0px;
        color: white;
        background-color: black;
    }
    .videosec{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .videosecleft{
        width: 100%;
        display: flex;
    }
    .videosecleft{
        width: 100%;
        height: 100%;
    }
    .videosecleft iframe{
        width: 100%;
        height: 400px;
    
    }
    .videosecright{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 30px 0px;
    }
    .videobottom{
        width: 100%;
        height: 400px;
        overflow-y: scroll;
        padding: 20px 0px;
        font-family: var( --robotoFont);
    }
    .videobottom::-webkit-scrollbar{
        display: none;
    }
    .videocon{
        width: 100%;
        height:auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 7px;
        background-color: #f6e999;
        box-shadow: 0 3px 12px 0 gainsboro;
    }
    .videocon button{
        padding: 6px 30px;
        color: black;
        border: none;
        outline: none;
        border-radius: 5px;
        font-size: 15px;
        font-weight: bold;
    }
    .videocon img{
        width: 80px;
        height: 50px;
        object-fit: contain;
         margin-right: 15px;
    }
    
    .videotop{
        width: 100%;
        margin:0px 0px 20px 0px;
        padding: 0px 20px;
    }
    .videotop h3{
        text-align: start;
    }
    .videocon1 h4{
        text-align: start;
    }
    /* The entire scrollbar */
    ::-webkit-scrollbar {
        width: 0px; /* Width of the scrollbar */
        height: 0px; /* Height of the scrollbar for horizontal scrolling */
    }
    
    /* The part of the scrollbar that moves */
    .videobottom::-webkit-scrollbar-thumb {
        background-color: #f6e999; /* Color of the scroll thumb */
        border-radius: 10px; /* Rounded corners */
    }
    
    /* The part of the scrollbar that stays in place */
    .videobottom::-webkit-scrollbar-track {
        background-color: #f1f1f1; /* Background color of the track */
        border-radius: 10px; /* Rounded corners */
    }
    
    /* The part of the scrollbar on hover */
    .videobottom::-webkit-scrollbar-thumb:hover {
        background-color: #f6e999; /* Color of the scroll thumb when hovered */
    }
    .footer{
        width: 100%;
        height: auto;
        background-color: black;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
    }
    .footer1{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .footer2{
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .footercontent{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }
    .footer2 h2{
        font-size: 22px;
        color: yellow;
    }
    .f1{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        
    }
    .f1 p{
        font-size: 16px;
        text-align: start;
        line-height: 25px;
    }
    .social{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 20px;
    }
    .social h1{
        font-size: 18px;
        margin-bottom: 10px;
    }
    .social1{
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .social1 p{
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 4px 0px 4px 0px;
    }
    .social1 p .soicon{
        margin-right: 10px;
        font-size: 16px;
    }
    .f2{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content:flex-start;
        flex-direction: column;
        padding-left: 0px;
        margin-top: 20px;
        position: relative;
    
    }
    .f2::before{
        position: absolute;
        content: "";
        width: 2px;
        height: 100px;
        background-color: var(--primaryBgColor);
        right: 130px;
        top: 50px;
    }
    .f3{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content:flex-start;
        flex-direction: column;
        padding-left: 0px;
        margin-top: 20px;
        position: relative;
    
    }
    .footer2 h2{
        display: none;
    }
    .f3 h2{
        font-size: 18px;
        margin-bottom: 20px;
    }
    .f3 h4{
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 13px;
    }
    .f2 h2{
        font-size: 18px;
        margin-bottom: 20px;
    }
    .f2 h4{
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 13px;
    }
    .footercontent1{
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        border-top: 1px solid white;
    }
   
    .navbarTitle img{
        width: 150px;
    }
    
}